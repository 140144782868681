import React from 'react';

import { classNames } from 'utils/classNames';
import styles from '../socialSharingIcon.module.css';
import stylesEmail from './EmailIcon.module.css';

const EmailIcon = (): React.ReactElement => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames([styles.icon, stylesEmail.icon])}
  >
    <path
      d="M13.3333 2.6665H2.66666C1.93333 2.6665 1.33999 3.2665 1.33999 3.99984L1.33333
     11.9998C1.33333 12.7332 1.93333 13.3332 2.66666 13.3332H13.3333C14.0667 13.3332
     14.6667 12.7332 14.6667 11.9998V3.99984C14.6667 3.2665 14.0667 2.6665 13.3333
     2.6665ZM13.3333 5.33317L8 8.6665L2.66666 5.33317V3.99984L8 7.33317L13.3333
     3.99984V5.33317Z"
    />
  </svg>
);

export default EmailIcon;
