import ReactTooltip from 'react-tooltip';
import React from 'react';

export const getEmailLink = (url: string, name: string | undefined, title: string): string =>
  `mailto:?subject=${title}&body=${url?.replace('=', '%3D')}${
    name ? `%23${name}` : ''
  }%0A%0AThis%20content%20is%20not%20owned%20or%20verified%20by%20BCD%20Travel.` +
  '%20This%20information%20is%20provided%20as%20is%20for%20information%20purposes%20only,' +
  '%20and%20BCD%20Travel%20does%20not%20warrant%20the%20accuracy%20of%20its%20contents.';

export const getFacebookLink = (url: string, name: string | undefined): string =>
  `https://www.facebook.com/sharer/sharer.php?u=${url}%23${name}`;

export const getTwitterLink = (url: string, name: string | undefined, title: string): string =>
  `https://twitter.com/intent/tweet?text=${encodeURI(title)}&amp;&url=${url}%23${name}`;

export const getLinkedinLink = (url: string, name: string | undefined, title: string): string =>
  `https://www.linkedin.com/shareArticle?mini=true&title=${encodeURI(title)}&url=${url}%23${name}`;

export const getShareLink = (url: string, name: string | undefined): string =>
  `${url}${name ? `#${name}` : ''}`;

export const shareHandler = (
  title: string,
  shareLink: string,
  input: React.MutableRefObject<HTMLInputElement | null>,
  tooltip: React.MutableRefObject<HTMLSpanElement | null>,
): void => {
  if (navigator.share) {
    navigator.share({
      title,
      url: shareLink,
    });
  } else {
    input?.current?.select();
    document.execCommand('copy');

    ReactTooltip.show(tooltip.current as Element);

    setTimeout(() => {
      ReactTooltip.hide(tooltip.current as Element);
    }, 1000);
  }
};
