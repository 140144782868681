/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useEffect } from 'react';
import { NeedToKnowList } from '@components/NeedToKnow/NeedToKnowList';
import * as contentful from 'types/contentful';
import { MainMenu } from '@components/MainMenu';
import { IPageProps } from '../types';

const NeedToKnow = (props: IPageProps<contentful.InformationCard[]>): React.ReactElement => {
  const {
    uri,
    navigate,
    pageContext,
    location: { pathname, href, hash },
  } = props;

  useEffect(() => {
    if (hash) {
      const hashId = hash.substring(1);

      const findCard = document.getElementById(hashId);

      if (findCard) {
        findCard.scrollIntoView();
      }
    }
  }, [hash]);

  return (
    <>
      <MainMenu uri={uri} navigate={navigate} staticUri={pathname} />
      <NeedToKnowList
        getItemProps={item => ({
          url: href?.replace(hash, ''),
          description: item.description?.json,
          title: item.title,
          updatedAt: item.updatedAt,
          key: item.id,
          id: item.id,
          image: item.image
            ? {
                url: item.image.file.url,
                title: item.image.title,
              }
            : undefined,
          link: item.infoUrl
            ? {
                url: item.infoUrl.link,
                label: item.infoUrl.label,
              }
            : undefined,
        })}
        data={pageContext.data}
      />
    </>
  );
};

export default NeedToKnow;
