/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useRef, useState } from 'react';

import { EmailIcon } from '@components/CustomizedIcons/EmailIcon';
import { FacebookIcon } from '@components/CustomizedIcons/FacebookIcon';
import { TwitterIcon } from '@components/CustomizedIcons/TwitterIcon';
import { LinkedinIcon } from '@components/CustomizedIcons/LinkedinIcon';
import { ShareIcon } from '@components/CustomizedIcons/ShareIcon';
import { classNames } from 'utils/classNames';
import styles from './SocialShare.module.css';
import {
  getEmailLink,
  getFacebookLink,
  getLinkedinLink,
  getShareLink,
  getTwitterLink,
  shareHandler,
} from './helpers';

type Props = {
  title: string;
  url: string;
  id: string;
  showLinkedin?: boolean;
  showTwitter?: boolean;
  showFacebook?: boolean;
  name?: string;
  iconDirectionCenter?: boolean;
};

function SocialShare(props: Props): React.ReactElement {
  const input = useRef<HTMLInputElement>(null);
  const tooltip = useRef<HTMLSpanElement>(null);

  const { url, title, id, showLinkedin, showTwitter, showFacebook, name } = props;

  const [isClient, setClient] = useState(false);

  const key = (isClient ? 'client' : 'server') + id;

  useEffect(() => {
    setClient(true);
  }, []);

  const emailLink = getEmailLink(url, name, title);
  const facebookLink = getFacebookLink(url, name);
  const twitterLink = getTwitterLink(url, name, title);
  const linkedinLink = getLinkedinLink(url, name, title);
  const shareLink = getShareLink(url, name);

  const shareClickHandler = (): void => {
    shareHandler(title, shareLink, input, tooltip);
  };

  return (
    <div
      className={classNames([styles.socialBlock, props.iconDirectionCenter ? styles.center : ''])}
      key={key}
    >
      <span className={styles.text}>Share</span>

      <a
        target="_blank"
        rel="noopener noreferrer"
        className={styles.icon}
        href={emailLink}
        title="Email about this article"
      >
        <EmailIcon />
      </a>

      {showFacebook && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.icon}
          href={facebookLink}
          title="Share on Facebook"
        >
          <FacebookIcon />
        </a>
      )}
      {showTwitter && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.icon}
          href={twitterLink}
          title="Tweet about this on Twitter"
        >
          <TwitterIcon />
        </a>
      )}
      {showLinkedin && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.icon}
          href={linkedinLink}
          title="Share on Linkedin"
        >
          <LinkedinIcon />
        </a>
      )}

      <a className={styles.icon} onClick={shareClickHandler} title="Share link">
        <ShareIcon />
      </a>
      <form className={styles.copiedText}>
        <input ref={input} type="text" defaultValue={shareLink} />
      </form>
      <span ref={tooltip} data-tip={JSON.stringify({ text: 'Copied to clipboard' })} />
    </div>
  );
}

export default SocialShare;
