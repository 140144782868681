import React from 'react';
import { classNames } from 'utils/classNames';
import styles from '../socialSharingIcon.module.css';
import stylesShare from './ShareIcon.module.css';

const ShareIcon = (): React.ReactElement => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames([styles.icon, stylesShare.icon])}
  >
    <path
      d="M12 10.7202C11.4933 10.7202 11.04 10.9202 10.6933 11.2335L5.94
    8.46683C5.97333 8.3135 6 8.16016 6 8.00016C6 7.84016 5.97333 7.68683 5.94
    7.5335L10.64 4.7935C11 5.12683 11.4733 5.3335 12 5.3335C13.1067 5.3335 14
    4.44016 14 3.3335C14 2.22683 13.1067 1.3335 12 1.3335C10.8933 1.3335 10
    2.22683 10 3.3335C10 3.4935 10.0267 3.64683 10.06 3.80016L5.36 6.54016C5
    6.20683 4.52667 6.00016 4 6.00016C2.89333 6.00016 2 6.8935 2 8.00016C2 9.10683
     2.89333 10.0002 4 10.0002C4.52667 10.0002 5 9.7935 5.36 9.46016L10.1067
     12.2335C10.0733 12.3735 10.0533 12.5202 10.0533 12.6668C10.0533 13.7402
     10.9267 14.6135 12 14.6135C13.0733 14.6135 13.9467 13.7402 13.9467
     12.6668C13.9467 11.5935 13.0733 10.7202 12 10.7202Z"
    />
  </svg>
);

export default ShareIcon;
