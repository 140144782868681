import React from 'react';

import { classNames } from 'utils/classNames';
import styles from '../socialSharingIcon.module.css';
import stylesFacebook from './FacebookIcon.module.css';

const FacebookIcon = (): React.ReactElement => (
  <svg
    className={classNames([styles.icon, stylesFacebook.icon])}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 8.00016C14.6667 4.31826 11.6819 1.3335 8 1.3335C4.31809 1.3335
    1.33333 4.31826 1.33333 8.00016C1.33333 11.3276 3.77122 14.0857 6.95833
    14.5858V9.92725H5.26562V8.00016H6.95833V6.53141C6.95833 4.86058 7.95364 3.93766
    9.47643 3.93766C10.2056 3.93766 10.9687 4.06787 10.9687 4.06787V5.7085H10.1281C9.3
    5.7085 9.04166 6.22243 9.04166 6.75016V8.00016H10.8906L10.595 9.92725H9.04166V14.5858C12.2288
     14.0857 14.6667 11.3276 14.6667 8.00016Z"
    />
  </svg>
);

export default FacebookIcon;
