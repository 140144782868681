import React from 'react';

import styles from '../socialSharingIcon.module.css';

const TwitterIcon = (): React.ReactElement => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
    <path
      d="M5.52787 13.5002C10.5581 13.5002 13.3102 9.33175 13.3102
    5.71794C13.3102 5.60076 13.3076 5.48097 13.3023 5.36378C13.8377
    4.97661 14.2997 4.49705 14.6667 3.94763C14.1681 4.16947 13.6387
    4.31435 13.0966 4.37732C13.6674 4.03519 14.0947 3.49773 14.2995
    2.86456C13.7625 3.18278 13.1753 3.40725 12.563 3.52836C12.1505
    3.09 11.605 2.79975 11.0109 2.70249C10.4169 2.60524 9.80733
    2.70638 9.27653 2.9903C8.74572 3.27421 8.32323 3.72508 8.07436
    4.27319C7.8255 4.82131 7.76413 5.43614 7.89974 6.02263C6.8125
    5.96807 5.74886 5.68564 4.77777 5.19363C3.80668 4.70163 2.94983
    4.01104 2.26276 3.16664C1.91356 3.76871 1.8067 4.48116 1.96391
    5.15918C2.12112 5.83721 2.53059 6.42994 3.10912 6.8169C2.6748
    6.80311 2.24999 6.68618 1.86979 6.47576V6.50961C1.86941 7.14144
    2.08783 7.7539 2.48795 8.24289C2.88807 8.73188 3.44518 9.06722
    4.06459 9.1919C3.66226 9.30198 3.23999 9.31802 2.83047 9.23878C3.00526
    9.78215 3.34532 10.2574 3.80321 10.5982C4.2611 10.939 4.81396 11.1284
    5.38464 11.1398C4.41579 11.9009 3.21899 12.3136 1.98698 12.3117C1.7685
    12.3114 1.55022 12.298 1.33334 12.2716C2.58492 13.0745 4.04085 13.501
    5.52787 13.5002Z"
    />
  </svg>
);

export default TwitterIcon;
