/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { SyntheticEvent, useCallback, useState } from 'react';
import * as contentful from 'types/contentful';
import { ContentNode } from '@components/ContentNode';
import SocialShare from '@components/SocialShare';
import CardExpandSign from '@components/CardExpandSign';

import {
  getCardFormattedDate,
  createCardExpandHandler,
  closeCard,
  getName,
} from '@components/helpers';

import styles from './NeedToKnowCard.module.css';
import { classNames } from '../../../utils/classNames';

export type Props = React.PropsWithChildren<{
  title: string;
  description?: contentful.Content;
  updatedAt: string | null;
  id: string;
  link?: {
    url: string;
    label: string;
  };
  image?: {
    title: string;
    url: string;
  };
  url: string;
}>;

function NeedToKnowCard(props: Props): React.ReactElement | false {
  const { title, url, id } = props;

  const [expanded, setExpanded] = useState(false);

  const handleMouseDown = createCardExpandHandler(setExpanded, expanded);

  const closeCardHandler = useCallback(
    (event: SyntheticEvent): void => {
      closeCard(event, expanded);
    },
    [expanded],
  );

  const name = getName(title);

  return (
    !!props.description && (
      <div className={styles.anchor} id={name}>
        <div className={classNames([styles.cardContainer])} onMouseDown={handleMouseDown}>
          <div className={expanded ? styles.sticky : ''} onMouseDown={closeCardHandler}>
            <div
              className={classNames([
                styles.cardHeaderContainer,
                expanded ? styles.cardHeaderContainerOpen : '',
              ])}
            >
              <CardExpandSign isExpanded={expanded} />
              <div className={styles.cardTitleContainer}>
                <h3 className={styles.title}>{props.title}</h3>
                <div className={styles.cardHeaderDetails}>
                  {!!props.link && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={props.link.url}
                      className={styles.cardHeaderLink}
                    >
                      {props.link.label}
                    </a>
                  )}
                  {!!props.updatedAt && (
                    <div className={styles.cardHeaderTime}>
                      Updated {getCardFormattedDate(props.updatedAt)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames([styles.cardContent, expanded ? styles.expanded : ''])}
            data-close-content="false"
          >
            <div className={styles.cardContentTextContainer}>
              <div className={classNames([styles.cardContentText])}>
                {!!props.image && (
                  <div className={classNames([styles.cardContentImage, styles.mobileContent])}>
                    <img src={props.image.url} alt={props.image.title} />
                  </div>
                )}
                <ContentNode {...props.description!} />
              </div>
              <div className={styles.socialBlock}>
                <SocialShare
                  title={title}
                  url={url}
                  id={id}
                  name={name}
                  showLinkedin={true}
                  showTwitter={true}
                  showFacebook={true}
                  iconDirectionCenter={true}
                />
              </div>
            </div>

            {!!props.image && (
              <div className={classNames([styles.cardContentImage, styles.desktopContent])}>
                <img src={props.image.url} alt={props.image.title} />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}

export default NeedToKnowCard;
