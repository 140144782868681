import React from 'react';
import { NeedToKnowCard } from '@components/NeedToKnow/NeedToKnowCard';
import styles from './NeedToKnowList.module.css';

type Props<T> = {
  data?: T[];
  getItemProps: (item: T) => Parameters<typeof NeedToKnowCard>[0];
};

function NeedToKnowList<T>(props: Props<T>): React.ReactElement {
  return (
    <div className={styles.listContainer}>
      {props.data?.map(item => (
        <NeedToKnowCard {...props.getItemProps(item)} />
      ))}
    </div>
  );
}

export default NeedToKnowList;
