import React from 'react';

import styles from './CardExpandSign.module.css';

import expandSignExpanded from '../../../static/list-chevron-up.svg';
import expandSignCollapsed from '../../../static/list-chevron-down.svg';

type Props = {
  isExpanded: boolean;
};

function CardExpandSign(props: Props): React.ReactElement {
  return (
    <div className={styles.expandSignContainer}>
      <img
        src={props.isExpanded ? expandSignExpanded : expandSignCollapsed}
        className={styles.expandSign}
        alt="arrow"
      />
    </div>
  );
}

export default CardExpandSign;
