import React from 'react';
import { classNames } from 'utils/classNames';
import styles from '../socialSharingIcon.module.css';
import stylesLinkedin from './LinkedinIcon.module.css';

const LinkedinIcon = (): React.ReactElement => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames([styles.icon, stylesLinkedin.icon])}
  >
    <path
      d="M13.6797 1.3335H2.31771C1.77344 1.3335 1.33334 1.76318 1.33334 2.29443V13.7033C1.33334 14.2345
     1.77344 14.6668 2.31771 14.6668H13.6797C14.224 14.6668 14.6667 14.2345 14.6667 13.7059V2.29443C14.6667
     1.76318 14.224 1.3335 13.6797 1.3335ZM5.28907 12.6955H3.3099V6.33089H5.28907V12.6955ZM4.29948 5.4637C3.66407
      5.4637 3.15104 4.95068 3.15104 4.31787C3.15104 3.68506 3.66407 3.17204 4.29948 3.17204C4.93229 3.17204
      5.44531 3.68506 5.44531 4.31787C5.44531 4.94808 4.93229 5.4637 4.29948 5.4637ZM12.6953
       12.6955H10.7188V9.60173C10.7188 8.86475 10.7057 7.91423 9.69011 7.91423C8.66146 7.91423
        8.50521 8.71891 8.50521 9.54964V12.6955H6.53125V6.33089H8.42709V7.20068H8.45313C8.71615 6.70068
        9.36198 6.17204 10.3229 6.17204C12.3255 6.17204 12.6953 7.48975 12.6953 9.20329V12.6955Z"
    />
  </svg>
);

export default LinkedinIcon;
